<template>
  <div class="mb-12">
    <div class="mb-10 h-80 lg:h-96">
      <apex-chart
        class="mx-auto"
        :type="props.chartType"
        height="100%"
        :options="chartOptionsMedian"
        :series="props.seriesMedian"
      />
    </div>
    <div class="h-80 lg:h-96">
      <apex-chart
        class="mx-auto"
        :type="props.chartType"
        height="100%"
        :options="chartOptionsEmployment"
        :series="props.seriesEmployment"
      />
    </div>
    <p class="text-xs text-blue-gray-500">
      Contains data from
      <a
        href="https://data.gov.sg/dataset/graduate-employment-survey-ntu-nus-sit-smu-suss-sutd"
        target="_blank"
        rel="noopener noreferrer"
        class="underline sm:hover:text-sky-800"
        >Graduate Employment Survey</a
      >
      from
      <a
        href="https://www.moe.gov.sg/"
        target="_blank"
        rel="noopener noreferrer"
        class="underline sm:hover:text-sky-800"
        >MOE</a
      >
      made available under
      <a
        href="https://data.gov.sg/open-data-licence"
        target="_blank"
        rel="noopener noreferrer"
        class="underline sm:hover:text-sky-800"
        >Singapore Open Data Licence v1.0</a
      >
    </p>
  </div>
</template>

<script setup>
import ApexChart from "vue3-apexcharts";

const props = defineProps({
  chartType: {
    type: String,
    required: true,
  },
  seriesMedian: {
    type: Object,
    required: true,
  },
  seriesEmployment: {
    type: Object,
    required: true,
  },
});
// eslint-disable-next-line no-unused-vars
const commonChartOptions = {
  colors:
    props.chartType === "bar" ? ["#16A34A", "#0284C7"] : ["#22C55E", "#0EA5E9"],
  fill: {
    opacity: 0.8,
    type: props.chartType === "bar" ? "solid" : "gradient",
    gradient: {
      type: "vertical",
      opacityFrom: props.chartType === "bar" ? 1 : 0.4,
      opacityTo: props.chartType === "bar" ? 1 : 0.2,
    },
  },
  stroke: {
    width: 4,
    lineCap: "round",
  },
  xaxis: {
    type: "datetime",
    labels: {
      style: {
        fontWeight: 500,
        fontSize: "0.625rem",
      },
      format: "yyyy",
    },
    tooltip: {
      enabled: false,
    },
  },
  tooltip: {
    x: {
      format: "yyyy",
    },
  },
  legend: {
    position: "top",
    offsetY: -10,
    fontWeight: 500,
    showForSingleSeries: true,
    showForNullSeries: false,
    onItemClick: {
      toggleDataSeries: false,
    },
  },
  markers: {
    size: 3,
    strokeWidth: 0,
    hover: {
      sizeOffset: 1,
    },
  },
  subtitle: {
    text: "Made with SGUniStats (sgunistats.com)",
    align: "left",
    offsetY: 24,
    style: {
      fontSize: "14px",
      fontWeight: "normal",
      color: "#9699a2",
    },
  },
};

const chartOptionsMedian = {
  ...commonChartOptions,
  chart: {
    id: "sgunistats-median-ges",
    group: "course-dashboard",
    toolbar: { show: false },
    fontFamily:
      "Inter, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    foreColor: "#1F2A38",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: props.chartType === "bar" ? true : false,
    style: {
      fontSize: "12px",
    },
    formatter: function (value) {
      if (value > 1000)
        return `$${value.toString()[0]},${value.toString().slice(1)}`;
      return "$" + value;
    },
  },
  yaxis: {
    labels: {
      minWidth: 24,
      formatter: function (value) {
        return `$${Math.round(value / 100) / 10}k`;
      },
      style: {
        fontWeight: 500,
        fontSize: "0.625rem",
      },
    },
  },
  tooltip: {
    x: {
      format: "yyyy",
    },
    y: {
      formatter: function (value) {
        if (value > 1000)
          return `$${value.toString()[0]},${value.toString().slice(1)}`;
        return "$" + value;
      },
    },
  },
  noData: {
    text: `There is no median income data for this course.`,
    style: {
      fontSize: "0.875rem",
    },
  },
  title: {
    text: "GES Median Income",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#263238",
    },
  },
};

const chartOptionsEmployment = {
  ...commonChartOptions,
  chart: {
    id: "sgunistats-employment-ges",
    group: "course-dashboard",
    toolbar: { show: false },
    export: {
      png: {
        filename: "sgunistats-employment-ges.png",
      },
    },
    fontFamily:
      "Inter, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    foreColor: "#1F2A38",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: props.chartType === "bar" ? true : false,
    formatter: function (value) {
      return `${Math.round(value * 100)}%`;
    },
    style: {
      fontSize: "14px",
    },
  },
  yaxis: {
    labels: {
      minWidth: 24,
      formatter: function (value) {
        return `${Math.round(value * 100)}%`;
      },
    },
    min: function (min) {
      // Rounding to higher 5% then subtracting 10%
      return min < 0.1 ? 0 : Math.ceil(min * 20) / 20 - 0.1;
    },
    max: function (max) {
      // Rounding to lower 5% then adding 5%
      return max > 0.9 ? 1 : Math.floor(max * 20) / 20 + 0.05;
      // return 1;
    },
    style: {
      fontWeight: 500,
      fontSize: "0.625rem",
    },
  },
  noData: {
    text: `There is no employment rate data for this course.`,
    style: {
      fontSize: "0.875rem",
    },
  },
  tooltip: {
    x: {
      format: "yyyy",
    },
    y: {
      formatter: function (value) {
        return `${Math.round(value * 100)}%`;
      },
    },
  },
  title: {
    text: "GES Employment Rate",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "#263238",
    },
  },
};
</script>
