<template>
  <apex-chart
    class="mx-auto"
    type="bar"
    :height="192"
    :options="chartOptions"
    :series="props.series"
  />
</template>

<script setup>
import ApexChart from "vue3-apexcharts";

const props = defineProps({
  series: {
    type: Array,
    required: true,
  },
  barTitle: {
    type: String,
    required: true,
  },
});

const chartOptions = {
  colors: ["#0284C7", "#DB2777"],
  chart: {
    id: "sgunistats-course-population",
    stacked: true,
    stackType: "100%",
    toolbar: { show: false },
    fontFamily:
      "Inter, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
    foreColor: "#1F2A38",
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  xaxis: {
    labels: {
      formatter: function (value) {
        return `${value}%`;
      },
      style: {
        fontWeight: 500,
        fontSize: "0.875rem",
      },
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  dataLabels: {
    formatter: function (value, { seriesIndex, w }) {
      return `${w.config.series[seriesIndex].data[0]} (${Math.round(value)}%)`;
    },
    style: {
      colors: ["#075985", "#9D174D"],
    },
    background: {
      enabled: true,
      opacity: 0.5,
    },
  },
  legend: {
    fontWeight: 500,
    showForSingleSeries: true,
    showForNullSeries: false,
    onItemClick: {
      toggleDataSeries: false,
    },
  },
  noData: {
    text: `No data available for course population`,
    style: {
      fontSize: "0.875rem",
    },
  },
  title: {
    text: props.barTitle,
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#263238",
    },
  },
  subtitle: {
    text: "Made with SGUniStats (sgunistats.com)",
    offsetY: 24,
    style: {
      fontSize: "14px",
      fontWeight: "normal",
      color: "#9699a2",
    },
  },
  tooltip: {
    x: {
      show: false,
    },
  },
};
</script>
