<template>
  <section ref="dashboard" class="w-full pt-12 pb-12 bg-blue-gray-100">
    <div class="container px-3 mx-auto sm:px-0">
      <div class="mx-auto">
        <!-- Dashboard Header -->
        <div class="flex items-center justify-start gap-2">
          <h2 class="mb-0.5 text-xl font-bold text-blue-gray-800">
            {{ courseData.major }}
          </h2>
          <app-icon :tag="courseFullData.tags[0]" />
        </div>
        <h3 class="mb-4 font-semibold text-blue-gray-700">
          {{ courseData.university }} |
          <span class="text-blue-gray-600">
            {{ courseData.faculty }}
          </span>
        </h3>
        <!-- Course Tags -->
        <div class="flex flex-wrap items-center flex-grow flex-shrink mb-6">
          <p
            v-for="tag in courseFullData.tags"
            class="
              bg-blue-600
              text-blue-50 text-xs
              font-semibold
              py-0.5
              px-2.5
              rounded-full
              mr-2
              mb-1
              whitespace-nowrap
              shadow-sm
            "
            :key="tag"
          >
            #{{ tag }}
          </p>
        </div>
        <!-- Course Information -->
        <div class="grid grid-cols-2 mb-12">
          <div v-if="courseData.cop">
            <p class="mb-4 font-bold text-blue-gray-800">Cut-off Score</p>
            <ul>
              <li class="mb-2 font-semibold leading-3 list-inside">
                {{ courseData.cop }} <span class="text-sm font-normal">RP</span>
              </li>
              <li class="mb-2 font-semibold leading-3 list-inside">
                {{ courseData.igp }}
                <span class="text-sm font-normal">grades</span>
              </li>
              <li
                v-if="courseData.gpa"
                class="mb-2 font-semibold leading-3 list-inside"
              >
                {{ courseData.gpa }}
                <span class="text-sm font-normal">GPA</span>
              </li>
            </ul>
          </div>
          <div v-if="courseHasGES">
            <p class="font-bold text-blue-gray-800">Latest Job Prospects</p>
            <p class="mb-3 text-xs font-medium">
              ({{ courseFullData.yearGES }} GES)
            </p>
            <ul>
              <li class="mb-2 font-semibold leading-3 list-inside">
                ${{ courseFullData.medianGES }}
                <span class="text-xs font-normal">median salary</span>
              </li>
              <li class="mb-2 font-semibold leading-3 list-inside">
                {{ Math.round(courseFullData.employmentGES * 100) }}%
                <span class="text-xs font-normal">employment rate</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mb-10 space-y-6">
        <!-- GES Statistics -->
        <app-chart-sync
          v-if="courseHasGES"
          :chart-type="courseLimitedGES ? 'bar' : 'area'"
          :series-median="syncSeries.median"
          :series-employment="syncSeries.employment"
        />
        <!-- Course Population -->
        <app-chart-bar
          v-if="courseHasPopulation"
          :bar-title="barTitle"
          :series="[
            { name: 'Male', data: [courseFullData.male] },
            { name: 'Female', data: [courseFullData.female] },
          ]"
        />
      </div>
      <p class="mb-4 font-bold text-blue-gray-800">Learn More</p>
      <!-- Course Website -->
      <a
        :href="courseFullData.link"
        target="_blank"
        rel="noopener noreferrer"
        class="
          block
          p-4
          transition-all
          duration-300
          rounded-2xl
          shadow
          sm:hover:shadow-md sm:hover:-translate-y-1.5
          bg-gradient-to-br
          max-w-full
        "
        :class="[`from-blue-100`, `to-sky-200`]"
      >
        <p class="mb-1.5 text font-bold text-blue-gray-800">
          {{ courseData.university }} {{ courseData.major }} Website
        </p>
        <p class="overflow-hidden text-xs underline truncate text-sky-800">
          {{ courseFullData.link }}
        </p>
      </a>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";

import AppChartSync from "@/components/AppChartSync.vue";
import AppChartBar from "@/components/AppChartBar.vue";
import AppIcon from "@/components/AppIcon.vue";

import courses from "@/data/courses.json";
import coursesFull from "@/data/coursesFull.json";
import { hasGES, hasPopulation, limitedGES } from "@/data/courseLists.json";
import medianGES from "@/data/medianGES.json";
import employmentGES from "@/data/employmentGES.json";

const route = useRoute();

const courseData = courses[route.params.id];
const courseFullData = coursesFull[route.params.id];
const courseHasGES = hasGES.indexOf(+route.params.id) !== -1;
const courseLimitedGES = limitedGES.indexOf(+route.params.id) !== -1;
const courseHasPopulation = hasPopulation.indexOf(+route.params.id) !== -1;

const barTitle = computed(() => {
  return `${courseData.university} ${
    courseFullData.populationFaculty ? courseData.faculty : courseData.major
  } Population`;
});

const syncSeries = computed(() => {
  if (hasGES.indexOf(+route.params.id) !== -1) {
    const allSeries = {
      median: [
        {
          name: `${courseData.major} (${courseData.university})`,
          data: medianGES[route.params.id],
        },
      ],
      employment: [
        {
          name: `${courseData.major} (${courseData.university})`,
          data: employmentGES[route.params.id],
        },
      ],
    };
    if (courseData.child) {
      allSeries.median.push({
        name: `${courses[courseData.child].major} (${courseData.university})`,
        data: medianGES[courseData.child],
      });
      allSeries.employment.push({
        name: `${courses[courseData.child].major} (${courseData.university})`,
        data: employmentGES[courseData.child],
      });
    }
    return allSeries;
  } else return null;
});

const dashboard = ref(null);

onMounted(() => {
  dashboard.value.scrollIntoView();
});
</script>
